import request from "@/utils/request.js";

/**
 * 注意：项目公共的接口，统一放在此文件，并函数名称以 'app' 开头
 */

// 获取用户菜单
export const appGetUserMenu = () => request("/admin/employe/front/menus");

// 判断警员编号是否唯一
export const appCheckJobNumber = (jobNumber, id) =>
  request("/admin/employe/jobNumberOnly", { jobNumber, id });

// 根据用户名和密码获取 Token
export const appLogin = (username, password) =>
  request("/admin/login", { username, password }, "POST");

// 根据 token 获取用户信息
export const appGetInfo = token =>
  request("/admin/employe/login/info", { token }, "GET");

// 获取所有数据
export const appGetAllRole = () => request("/admin/role/all");

// 查询所有部门
export const appGetAllDepart = () => request("/admin/depart/getList");

// 获取所有组
export const appGetAllGroups = () => request("/admin/group/getGroups");

// 获取所有组
export const appGetAllGroupsByDepartId = departId =>
  request(`/admin/group/getAllGroupsByDepartId/${departId}`);

/**
 * 根据具体code查询相关数据
 * code:
 * case_type：案件类型
 * vehicle_type 获取车辆类型相关信息
 * blacklist_type 获取黑名单相关信息
 * algorithm_type 获取算法类型相关信息
 * depart_level 获取部门级别
 * device_place 设备位置
 * brand_type 获取号牌种类
 */
export const appDictionariesDetail = type =>
  request(`/admin/dict/dictValue/getValuesByCategoryCode/${type}`);

// 查询所有道路
export const appGetAllRoad = () => request("/basisRoad/getRoadAll");
export const getFjdcList = () => request("/trafficIllegal/getFjdcList");

// 修改密码
export const appModifyPass = params =>
  request("/admin/employe/updatePassword", params, "POST");

export const getBoxVersionList = () => request("/boxVersion/getAll");
