import axios from 'axios'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import handleRequestError from '@/utils/handleRequestError'
import 'nprogress/nprogress.css'

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  timeout: 20000
})

// 请求拦截
service.interceptors.request.use(config => {
  if (getToken()) {
    config.headers.Authorization = getToken()
  }
  // 单独控制 get_spike接口地址的请求时间
  if (config.url.indexOf('caseInfo/report') > -1) {
    config.timeout = 30000
  }
  config.headers.lang = 'zh_CN'
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use((res) => {
  return res
}, function (error) {
  const { status, code, message } = error.response.data
  handleRequestError(status || code, message)
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default function request (url, data = {}, method = 'GET') {
  return new Promise((resolve, reject) => {
    NProgress.start()
    let promise
    switch (method) {
      case 'GET':
        promise = service.get(url, { params: data })
        break
      case 'POST':
        promise = service.post(url, data)
        break
      case 'PUT':
        promise = service.put(url, data)
        break
      case 'DELETE':
        promise = service.delete(url, data)
        break
    }

    promise.then(response => {
      const { code, data, message } = response.data
      if (code === 20000) {
        resolve(data)
      } else {
        reject(message)
        handleRequestError(code, message)
      }
    }).catch((error) => {
      reject(error)
    }).finally(() => {
      NProgress.done()
    })
  })
}
