<template>
  <a-config-provider :locale="locale">
    <div id="app" style="height: 100%; width: 100%; overflow: auto;">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'App',
  data () {
    return {
      locale: zhCN
    }
  },
  created () { }
}
</script>

<style lang="less">
</style>
