import { message } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'

const messages = {
  404: '无法找到该请求',
  40003: '用户登录失败 5 次后将被锁定',
  40010: '用户名或者密码错误',
  40013: '该用户不存在',
  40015: '登录超时',
  500: '服务器出小差了',
  51001: '该用户已存在'
}

const handleError = async (code, msg = '未知异常') => {
  // 异常消息提示
  message.destroy()

  message.error(`${(code && messages[code]) || msg}`)

  const path = router.currentRoute.path

  // 登录超时处理
  if (code === 40015 && path !== '/login') {
    await store.dispatch('user/logout')
    router.replace(`/login?redirect=${router.currentRoute.path}`)
  }
}

export default handleError
