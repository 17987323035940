<!-- 按钮权限控制 -->
<template>
  <span style="display: flex;">
    <!-- 普通按钮 -->
    <template v-if="btnType === 'default'">
      <!-- 有权限 -->
      <template v-if="hasPermission">
        <a-button :icon="icon" :type="type" @click="myClick">{{ $slots.default[0].text  }}</a-button>
      </template>
      <!-- 无权操作 -->
      <template v-else>
        <a-tooltip title="您暂无操作权限">
          <a-button :icon="icon" disabled>{{ $slots.default[0].text  }}</a-button>
        </a-tooltip>
      </template>
    </template>

    <!-- 图标按钮 -->
    <template v-if="btnType === 'icon'">
      <!-- 有权限 -->
      <template v-if="hasPermission">
        <a-tooltip placement="top" :title="$slots.default[0].text">
          <i :class="className" @click="myClick"></i>
        </a-tooltip>
      </template>
      <!-- 无权操作 -->
      <template v-else>
        <a-tooltip title="您暂无操作权限">
          <i :class="[className, 'disabled']"></i>
        </a-tooltip>
      </template>
    </template>

      <!-- <template v-if="true">
        <a-tooltip title="您暂无操作权限">
            <a-button disabled>{{ text }}</a-button>
        </a-tooltip>
      </template>
      <template v-else>
        <slot></slot>
      </template> -->
  </span>
</template>

<script>
export default {
  props: {
    btnType: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'default' // primary, danger, ...
    },
    operate: {
      type: String,
      default: 'query'
    },
    icon: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '未定义'
    },
    data: [String, Number, Boolean, Array, Object, Date, Function, Symbol]
  },
  computed: {
    hasPermission () {
      return this.$store.getters['user/getPageOperation'](this.$route.path)[this.operate]
    }
  },
  methods: {
    myClick () {
      this.$emit('click', this.data)
    }
  },
  created () {
    // const path = this.$route.path
  }
}
</script>

<style>

</style>
