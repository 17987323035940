import store from 'store'

/**
 * 控制左侧菜单显示隐藏
 * 说明：利用 localStorage 进行存储
 */
const COLLAPASED_KEY = 'app-collapased'

export function getCollapasedStatus () {
  return store.get(COLLAPASED_KEY) || false
}

export function setCollapased (isCollapased) {
  return store.set(COLLAPASED_KEY, isCollapased)
}
