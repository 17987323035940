<!-- 权限按钮控制 -->
<template>
  <span style="display: inline-block;">
      <template v-if="hasPermission">
          <a-button v-bind="$attrs" v-on="$listeners"><slot/></a-button>
      </template>
      <template v-else>
          <a-tooltip title="您暂无操作权限">
              <a-button v-bind="$attrs" disabled><slot/></a-button>
          </a-tooltip>
      </template>
  </span>
</template>

<script>
export default {
  computed: {
    hasPermission () {
      return this.$store.getters['user/getPageOperation'](this.$route.path)[this.$attrs.role]
    }
  }
}
</script>
