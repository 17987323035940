
import { getToken, setToken, removeToken } from '@/utils/auth'
import { appLogin, appGetInfo } from '@/api/app'
import { getUserMenu } from '@/utils/getUserMenu'

const state = {
  token: getToken(),
  userInfo: {},
  homePage: '', // 动态绑定，路由数据的第一个
  asyncRoutes: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },

  SET_ASYNCROUTES: (state, routes) => {
    state.asyncRoutes = routes
  },

  SET_HOMEPAGE: (state, homePage) => {
    state.homePage = homePage
  }
}

const actions = {
  login ({ commit }, loginForm) {
    const { username, password } = loginForm
    return new Promise((resolve, reject) => {
      appLogin(username, password).then(res => {
        const { token } = res
        commit('SET_TOKEN', token)
        setToken(token)
        getUserMenu().then(res => {
          resolve()
        })
      }).catch(error => {
        reject(error)
      })
    })
  },

  getUserInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      appGetInfo(state.token).then(userInfo => {
        commit('SET_USERINFO', userInfo)
        resolve(userInfo)
      }).catch(error => {
        reject(error)
      })
    })
  },

  setUserMenu ({ commit }, data) {
    const firstRoute = data && data.length > 0 && data[0]
    let path = ''
    if (firstRoute.children || firstRoute.children.length > 0) {
      path = firstRoute.children[0].path
    } else {
      path = firstRoute.path
    }
    commit('SET_HOMEPAGE', path)
    commit('SET_ASYNCROUTES', data)
  },

  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_USERINFO', {})
      commit('SET_ASYNCROUTES', [])
      removeToken()
      resolve()
    })
  }
}

const getters = {
  getPageOperation: (state) => (pagePath) => {
    let pageOperation = {}

    function filterRoute (data) {
      data.forEach(item => {
        if (item.children && item.children.length > 0) {
          filterRoute(item.children)
        } else {
          if (item.path === pagePath) {
            pageOperation = item
          }
        }
      })
      return pageOperation
    }

    return filterRoute(state.asyncRoutes)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
