<!-- 页面外层组件封装。type 可取值：app-tabs, ...（按需求扩展） -->
<template>
    <div :class="{'app-tab': type === 'app-tabs'}">
      <div class="left-placeholder"></div>
      <slot></slot>
    </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  }
}
</script>
<style scoped lang="less">
.app-tab {
  height: 100%;
  padding: 15px 0;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: start;
  .left-placeholder {
    height: 40px;
    border-bottom: 1px solid #143BAB;
  }

}
</style>
