import Vue from "vue";
import Router from "vue-router";
import Layout from "@/components/layout";
Vue.use(Router);

// 基本路由
export const constantRoutes = [
  {
    path: "/",
    component: () => import("@/components/direct")
  },
  {
    path: "/login",
    component: () => import("@/pages/login/Login"),
    meta: {
      title: "登录"
    },
    hidden: true
  },
  {
    path: "/modifyPassword",
    component: () => import("@/pages/login/modifyPassword"),
    meta: {
      title: "登录"
    },
    hidden: true
  },
  {
    path: "/user",
    component: Layout,
    children: [
      {
        path: "modifyPass",
        component: () => import("@/pages/login/modifyPass"),
        meta: {
          title: "修改密码"
        }
      }
    ]
  }
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
  // 初始化为最初的路由
  router.options.routes = constantRoutes;
}

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
