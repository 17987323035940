import store from '@/store'
import router from '@/router'
import { appGetUserMenu } from '@/api/app'

// 路由懒加载
export const loadView = (view) => {
  if (view === '/layout') {
    return (resolve) => require([`@/components${view}`], resolve)
  }
  return (resolve) => require([`@/pages${view}`], resolve)
}

export function filterAsyncRoutes (routes) {
  const res = []
  routes.forEach(route => {
    const temp = { ...route }
    const tempRoute = {
      id: temp.id,
      name: temp.name,
      path: temp.path,
      icon: temp.icon,
      component: temp.children ? loadView('/layout') : loadView(temp.path),
      meta: { title: temp.name, icon: temp.icon },
      add: temp.add,
      delete: temp.delete,
      update: temp.update,
      query: temp.query,
      children: temp.children,
      isRouteRoot: temp.isRouteRoot
    }

    if (tempRoute.children) {
      tempRoute.children = filterAsyncRoutes(tempRoute.children)
    }
    res.push(tempRoute)
  })
  return res
}

export function getUserMenu (update = false) {
  return new Promise((resolve, reject) => {
    const asyncRoutes = store.getters.asyncRoutes
    if (asyncRoutes.length > 0 && !update) {
      resolve(asyncRoutes)
    } else {
      appGetUserMenu().then(res => {
        const routes = res.map(item => {
          const tempItem = Object.assign({}, item)
          if (!item.children && item.parentId === -1) {
            tempItem.isRouteRoot = true // 手动包装
            tempItem.children = [item]
          }
          return tempItem
        })
        const routesList = filterAsyncRoutes(routes)
        store.dispatch('user/setUserMenu', routesList)
        if (routesList.length > 0) {
          router.addRoutes(routesList)
          router.options.routes = router.options.routes.concat(routesList)
        } else {
        //   MessageBox({
        //     message: '您暂无页面访问权限', // 你暂无操作权限
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
          store.dispatch('user/logout')
        }
        resolve(routesList)
      }).catch(error => {
        reject(error)
      })
    }
  })
}
