import Vue from "vue";
import App from "./App.vue";
import "lib-flexible";

import store from "./store";
import router from "./router";

import "@/utils/permission";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import "@/assets/css/antd-reset.less";
import "@/assets/css/theme.less";

import antConfig from "@/config/antd";

import LayoutPage from "@/components/layout-page";
import AuthButton from "@/components/auth-button";
import PermButton from "@/components/perm-button";

Vue.component("auth-button", AuthButton);
Vue.component("perm-button", PermButton);
Vue.component("LayoutPage", LayoutPage);

Vue.use(Antd);
Vue.prototype.$antConfig = antConfig;

Vue.config.productionTip = false;
Vue.prototype.$store = store;
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source"
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
