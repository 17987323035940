/**
 * 处理路由
 */

import { constantRoutes } from '@/router'

export function filterAsyncRoutes (routes) {
  const res = []
  routes.forEach(route => {
    const temp = { ...route }
    if (temp.children) {
      temp.children = filterAsyncRoutes(temp.children)
    }
    res.push(temp)
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = constantRoutes.concat(routes)
    state.addRoutes = routes
  }
}

const actions = { }

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
