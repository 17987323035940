import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import { getUserMenu } from '@/utils/getUserMenu'

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  document.title = (to.meta.title && `${to.meta.title} - 智能管理平台`) || '智能管理平台'

  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      const { name } = store.getters.userInfo
      if (name) {
        const asyncRoutes = store.getters.asyncRoutes
        if (asyncRoutes.length > 0) {
          next()
        } else {
          getUserMenu().then(res => {
            if (to.path === '/') {
              next({ path: store.getters.homePage, replace: true })
            } else {
              next({ ...to, replace: true })
            }
          })
        }
        next()
      } else {
        store.dispatch('user/getUserInfo').then(() => {
          next({ ...to, replace: true })
        }).catch(async () => {
          await store.dispatch('user/logout')
          next(`/login?redirect=${to.path}`)
        })
      }
    }
  } else {
    // 判断是否在白名单里面
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => { })
