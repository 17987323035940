<template>
      <a-layout id="app-layout" style="height: 100%; flex-direction: row;">
        <a-layout-sider class="app-layout-sider" v-model="collapsed" :width="179" :collapsedWidth="80" :trigger="null" collapsible>
            <div class="app-logo-wrap">
                <a-icon v-if="collapsed" type="home" />
                <div v-else class="app-logo">智慧管理平台</div>
            </div>
            <!-- 左侧菜单 -->
            <a-menu
              mode="inline"
              :default-open-keys="openKeys"
              :default-selected-keys="defaultSelectedKeys"
              :inline-collapsed="collapsed"
              @openChange="openChange"
            >
              <template v-for="item in asyncRoutes">
                <a-menu-item v-if="item.children && item.children.length === 1 && item.isRouteRoot" :key="item.path">
                  <router-link :to="item.path">
                        <a-icon :type="item.icon" />
                        <span>{{ item.name }}</span>
                    </router-link>
                </a-menu-item>

                <template v-else>
                  <a-sub-menu :key="item.path">
                    <span slot="title"><a-icon :type="item.icon" /><span>{{ item.name }}</span></span>
                    <template v-for="child in item.children">
                        <a-menu-item :key="child.path">
                            <router-link :to="child.path">
                                {{ child.name }}
                            </router-link>
                        </a-menu-item>
                    </template>
                  </a-sub-menu>
                </template>
              </template>
            </a-menu>

        </a-layout-sider>
        <a-layout>
        <a-layout-header class="app-layout-header">
            <a-icon class="trigger app-sidebar-collapsed" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="setCollapased"/>
            <div class="header-right">
                <div class="header-user-info">
                    <a-space size="large">
                        <a-space>
                          <a-icon type="user" style="font-size: 18px;"/>
                          <span>您好，{{ userInfo.name }}</span>
                        </a-space>
                        <!-- <a-icon class="logout" type="logout" @click="logout" /> -->
                        <a-dropdown :trigger="['click']">
                          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                            <a-icon type="down" style="font-size: 18px;color:#ffffff;margin-top: 24px;" />
                          </a>
                          <a-menu slot="overlay">
                            <a-menu-item key="0">
                              <a @click="modifyPass">修改密码</a>
                            </a-menu-item>
                            <a-menu-item key="1">
                              <a  @click="logout">退出登录</a>
                            </a-menu-item>
                          </a-menu>
                        </a-dropdown>
                    </a-space>
                </div>
            </div>
        </a-layout-header>
        <a-layout-content class="app-layout-content">
            <router-view></router-view>
        </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getCollapasedStatus, setCollapased } from '@/utils/menuCollapsed'

export default {
  data () {
    return {
      collapsed: false,
      defaultSelectedKeys: [],
      openKeys: []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    }),
    ...mapGetters({
      asyncRoutes: 'asyncRoutes'
    })
  },

  methods: {
    confirm () {
    },
    openChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      this.openKeys = [latestOpenKey]
    },
    logout () {
      this.$confirm({
        title: '您确定要退出吗？',
        onOk: () => {
          this.$store.dispatch('user/logout').then(res => {
            this.$router.replace(`/login?redirect=${this.$route.fullPath}`)
          })
        }
      })
    },
    // 修改密码
    modifyPass () {
      this.$router.push('/user/modifyPass')
    },
    setCollapased () {
      this.collapsed = !this.collapsed
      setCollapased(this.collapsed)
    },

    openCurrentMenu (asyncRoutes) {
      const path = this.$route.fullPath

      const currentMen = asyncRoutes.find(item => {
        if (path.indexOf(item.path) !== -1 && (item.children && item.children.length > 0)) {
          const cItem = item.children.find(item => item.path === path)
          if (cItem) {
            return item.path
          }
        }
      })

      return (currentMen && currentMen.path) || '/'
    }
  },
  created () {
    this.collapsed = getCollapasedStatus()
    const path = this.$route.fullPath
    this.defaultSelectedKeys = [path] // 定位到当前页面路由
    !this.collapsed && (this.openKeys = [this.openCurrentMenu(this.asyncRoutes)])
  }
}
</script>

<style scoped lang="less">
 @import './index.less';
</style>
