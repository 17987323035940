const state = {
  userInfo: {}
}

const mutations = {
  TOGGLE_SIDEBAR: state => {

  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  }
}

const getters = { }

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
