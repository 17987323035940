/**
 * Ant Design Vue 组件全局配置
 */

const antConfig = {
  table: {
    pagination: {
      current: 1,
      defaultPageSize: 10,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      total: 0,
      showTotal: total => `共 ${total} 条数据`,
      showSizeChanger: true,
      showQuickJumper: true
    }
  }
}

export default antConfig
